import * as React from "react"
import googlePlayLogo from '../images/google-play-badge.png';
import appStoreLogo from '../images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import Seo from "../components/seo";

const IndexPage = () => {
    React.useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            entries.forEach((entry) => {
                document.documentElement.style.setProperty("--webkit-footer-gap", `${entry.contentRect.height}px`);
            })
        })

        observer.observe(document.querySelector(".links-container"))
        return () => observer.disconnect()

    },[])

    return (
        <div className="App d-flex flex-column justify-content-between align-items-stretch p-3">
            <h1 className="text-center flex-grow-0">RETRO FALL</h1>

            <img className="d-block place-holder mx-auto m-3" src="https://storage.googleapis.com/pcs-cdn-bucket/v1.0_preview.gif" alt="Retro Fall Gameplay"/>

            <div className="links-container flex-grow-0 px-3 py-2 mb-n3 mx-n3 d-flex justify-content-center align-items-center gap-4">
                <a href="https://play.google.com/store/apps/details?id=software.picklecube.retrofall">
                    <img src={googlePlayLogo} alt="Google Play store download button" className="google" />
                </a>
                <a href="https://apps.apple.com/us/app/retro-fall/id6443681675">
                    <img src={appStoreLogo} alt="Apple AppStore download button" className="apple" />
                </a>
            </div>
        </div>
    );
}

export default IndexPage

export const Head = () => (
    <>
        <Seo title="Retro Fall" description="Download on iOS and Android" />
        <meta name="apple-itunes-app" content="app-id=6443681675" />
    </>
);
