import React from 'react';
import {graphql, useStaticQuery} from "gatsby";

const Seo = ({ description, title, _ }) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        siteUrl
                    }
                }
            }
        `,
    );

    return (
        <>
            <title>{title || site.siteMetadata.title}</title>
            <meta name="description" content={description || site.siteMetadata.description}/>
            <meta property="og:title" content={title || site.siteMetadata.title} />
            <meta property="og:description" content={description || site.siteMetadata.description}/>
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://retrofall.com" />
            <meta property="og:image" content={'https://retrofall.com/banner.png'} />
            <meta name="twitter:card" content="app" />
            <meta name="twitter:text:title" content={title || site.siteMetadata.title} />
            <meta name="twitter:description" content={description || site.siteMetadata.description}/>
            <meta name="twitter:site" content="@retrofall" />
            <meta name="twitter:creator" content="@retrofall" />
            <meta name="twitter:app:id:iphone" content="6443681675" />
            <meta name="twitter:app:id:ipad" content="6443681675" />
            <meta name="twitter:app:id:googleplay" content="software.picklecube.retrofall" />
            <meta name="twitter:app:url:iphone" content="retrofallgame://open" />
            <meta name="twitter:app:url:ipad" content="retrofallgame://open" />
            <meta name="twitter:app:url:googleplay" content="retrofallgame://open" />
            <meta name="twitter:app:id:ipad" content="6443681675" />
            <meta name="twitter:app:id:ipad" content="6443681675" />
        </>
    );
};

export default Seo;